import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { concat, defer, EMPTY, from, of } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_DOCUMENTO_OPENED } from '../../data.const';
import { setLoaders, setPopupInfo } from '../../data.action';

const upsertDocumentoOpenedEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_DOCUMENTO_OPENED),
    map(({ payload }: any) => payload),
    tap((p) => console.log(`[epic ${UPSERT_DOCUMENTO_OPENED}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ documentoLoader: true, page: true })),
        defer(() =>
          from(
            request({ path: `documento/opened`, method: httpMethods.PUT, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            tap((p) => console.log(`[epic ${UPSERT_DOCUMENTO_OPENED}]`, p)),
            switchMap(() => EMPTY),
            catchError((err) => {
              console.error(`[epic ${UPSERT_DOCUMENTO_OPENED}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: 'Operazione non riuscita',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ documentoLoader: false, page: false }))
      );
    })
  );

export default upsertDocumentoOpenedEpic;
