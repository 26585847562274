import { ofType } from 'redux-observable';
import {
  catchError,
  delay,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer, timer } from 'rxjs';
import { navigate } from '@reach/router';
import { httpMethods, request } from '../../../../../services/http-service';
import { UPSERT_RATA_INCARICO } from '../../data.const';
import {
  setCurrentRataIncaricoAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';

const upsertRataIncaricoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(UPSERT_RATA_INCARICO),
    tap((p) => console.log(`[epic ${UPSERT_RATA_INCARICO}]`, p)),
    map(({ payload }: any) => {
      const { createdAt, updatedAt, ...rest } = payload?.body;
      if (rest?.id && rest?.id === 'crea') {
        delete rest.id;
      }
      return { info: payload?.info, body: rest };
    }),
    tap((p) => console.log(`[epic ${UPSERT_RATA_INCARICO}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, info, listBody }, state]) => {
      return concat(
        of(setLoaders({ incaricoLoader: true, page: true })),
        defer(() =>
          from(
            request({ path: `rataIncarico`, method: httpMethods.POST, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message);
              }
              return p?.result;
            }),
            switchMap((data) =>
              concat(
                of(setCurrentRataIncaricoAction(data)),
                of(null).pipe(
                  delay(500),
                  switchMap(() =>
                    concat(
                      of(
                        setPopupInfo({
                          opened: true,
                          message: 'Operazione completata con successo',
                          timer: 3000,
                        })
                      ),
                      // of(
                      //   retrieveRataIncaricoListAction({
                      //     pageSize: rootConfig.pageSize,
                      //     page: 1,
                      //     order: [['descrizione', 'asc']],
                      //     filters: {
                      //       or: [
                      //         {
                      //           field: '$OnorarioIncarico.id$',
                      //           value: info?.onorarioId,
                      //         },
                      //       ],
                      //     },
                      //   })
                      // ),
                      of(null).pipe(
                        tap(() => {
                          info?.action && info?.action();
                        }),
                        switchMap(() => EMPTY)
                      )
                    )
                  )
                )
              )
            ),
            // switchMap(() => of(retrieveRataIncaricoListAction(listBody))),
            catchError((err) => {
              console.error(`[epic ${UPSERT_RATA_INCARICO}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ incaricoLoader: false, page: false }))
      );
    })
  );

export default upsertRataIncaricoEpic;
