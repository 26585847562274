import { ofType } from 'redux-observable';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { DELETE_DOCUMENTO, DELETE_DOCUMENTO_ONEDRIVE } from '../../data.const';
import { retrieveDocumentoListAction, setLoaders } from '../../data.action';

const deleteDocumentoEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(DELETE_DOCUMENTO),
    map(({ payload: { ids, isCestino, ...rest } }: any) => ({
      body: {
        ids,
        isCestino,
      },
      listBody: { ...rest, isCestino },
    })),
    // tap(p => console.log(`[epic ${DELETE_DOCUMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([{ body, listBody }, _state]) => {
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          from(
            request({ path: `documento`, method: httpMethods.DELETE, body })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return {
                type: DELETE_DOCUMENTO_ONEDRIVE,
                payload: body,
              };
            }),
            // tap(p => console.log(`[epic ${DELETE_DOCUMENTO}]`, p)),
            // switchMap(() => EMPTY),
            catchError((err) => {
              console.error(`[epic ${DELETE_DOCUMENTO}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(retrieveDocumentoListAction(listBody)).pipe(
          catchError((err) => {
            console.error(`[epic ${DELETE_DOCUMENTO}] error`, err);
            return EMPTY;
          })
        ),
        of(setLoaders({ documentoLoader: false }))
      );
    })
  );

export default deleteDocumentoEpic;
