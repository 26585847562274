const appName = 'app';
const prefix = process.env.GATSBY_PREFIX || '';

const domain =
  process.env.GATSBY_DOMAIN ||
  'https://api.nomox.it' ||
  'http://localhost:8000';

const sso_domain = process.env.GATSBY_SSO_DOMAIN || 'https://sso.nomox.it';
const sso_realms =
  process.env.GATSBY_STAGE === 'prd' || process.env.GATSBY_STAGE === 'stg'
    ? 'nomox'
    : 'Test';

const irnerioDocumentsProvider =
  'https://ke8uqcbh54.execute-api.eu-west-1.amazonaws.com/prd';
const dueDiligenceProvider =
  'https://nsa82zpx0c.execute-api.eu-west-1.amazonaws.com/prd';

const generalProvider =
  process.env.GATSBY_GESIONALE_PROVIDER ||
  // 'https://ab2wzhb0mb.execute-api.eu-west-1.amazonaws.com/stg' ||
  // 'https://byyhyygmea.execute-api.eu-west-1.amazonaws.com/prd' ||
  'http://localhost:3000';

export const creditiMap = {
  pec: 0.5,
  visuraCatastale: 3,
  visuraIpocatastale: 30,
};

const microsoft_config = {
  clientId: 'fe988707-459f-4c06-a8ad-e4a19e3e3c5e',
  tenantId: '660c9fb2-e9e0-4117-9284-9c8e3fa81677',
  clientSecret: 'p.N8Q~pBbgrmc3LMQjdhFqxsPSB_wYRuSQ2qnbw_',
  redirectUri: 'https://sso.nomox.it/realms/Test/broker/microsoft/endpoint',
  scopes:
    'openid,offline_access,Application.Read.All,Application.ReadWrite.All,Calendars.Read,Calendars.Read.Shared,Calendars.ReadWrite,Calendars.ReadWrite.Shared,Chat.Create,Chat.Read,Chat.ReadWrite,Chat.ReadWrite.All,ChatMessage.Read,ChatMessage.Send,Contacts.ReadWrite,Contacts.ReadWrite.Shared,DelegatedPermissionGrant.Read.All,Directory.Read.All,Files.ReadWrite.All,Mail.Read,Mail.Read.Shared,Mail.ReadWrite,Mail.ReadWrite.Shared,Mail.Send,Mail.Send.Shared,Schedule.Read.All,Sites.ReadWrite.All,User.Read,User.Read.All',
};

const apiCoreEndpoint =
  process.env.GATSBY_STAGE === 'prd' || process.env.GATSBY_STAGE === 'stg'
    ? 'https://apicore.nomox.it'
    : 'https://localhost:7293';

export const rootConfig = {
  isDebug: !process.env.GATSBY_DOMAIN,
  language: 'it',
  url: `${domain}/api`,
  irnerioDocumentsProvider: `${irnerioDocumentsProvider}`,
  urlDueDiligence: `${dueDiligenceProvider}`,
  endpointGestionale: `${generalProvider}/gestionale`,
  beEndpoint: `${generalProvider}`,
  apiCoreEndpoint: `${apiCoreEndpoint}`,
  documentEditorEndpoint: `${apiCoreEndpoint}/DocumentEditor`,
  pdfViewerEndpoint: `${apiCoreEndpoint}/PdfViewer`,
  endpointDrive: `${generalProvider}/drive`,
  app_base_path: `/${appName}`,
  app_path: `${prefix}/${appName}`,
  upload_url: `${domain}/api/uploadAudio.php`,
  audio_folder: `${domain}/api/uploads/audio/`,
  base_url: `${domain}`,
  wp_url: `${domain}/?rest_route=`,
  sso_endpoint: `${sso_domain}/realms/nomox/protocol/openid-connect`,
  sso_client_id: `nomox${
    process.env.GATSBY_STAGE === 'prd' ? '' : `-${process.env.GATSBY_STAGE}`
  }`,
  sso_login_ms_personal: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${microsoft_config.clientId}&response_type=code&scope=${microsoft_config.scopes}`,
  sso_login_ms_business: `https://login.microsoftonline.com/${microsoft_config.tenantId}/oauth2/v2.0/authorize?client_id=${microsoft_config.clientId}&response_type=code&scope=${microsoft_config.scopes}`,
  sso_admin_consent: `https://login.microsoftonline.com/common/adminconsent?client_id=${microsoft_config.clientId}`,
  // sso_admin_consent: `https://login.microsoftonline.com/common/adminconsent?client_id=${microsoft_config.clientId}&scope=${microsoft_config.scopes}`,
  stage: process.env.GATSBY_STAGE,
  endpointWpGestionale: `${domain}/?rest_route=/gestionale/v1`,
  modalTimeout: 600,
  pageSize: 10,
  populateFields: 101,
  innerPageSize: '95%',
  documentAutoSaveIntervalInMinutes: 1,
};

export const buildLoginBaseUrlForTenant = (tenant: string | null) => {
  return tenant
    ? `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?client_id=${microsoft_config.clientId}&response_type=code&scope=${microsoft_config.scopes}`
    : rootConfig.sso_login_ms_business;
};
