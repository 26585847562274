import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { EMPTY, from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_DOCUMENTO_OPENED } from '../../data.const';
import {
  resetCurrentCreateSessionAction,
  setDocumentoOpenedAction,
  setLoaders,
  setPopupInfo,
} from '../../data.action';

const retrieveDocumentoOpenedEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_DOCUMENTO_OPENED),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_DOCUMENTO}]`, p)),
    withLatestFrom(state$),
    switchMap(([id, _state]) => {
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          from(
            request({ path: `documento/opened/${id}`, method: httpMethods.GET })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error('Server error');
              }
              return p;
            }),
            tap((p) => console.log(`[epic ${RETRIEVE_DOCUMENTO_OPENED}]`, p)),
            switchMap((p) =>
              concat(
                of(setDocumentoOpenedAction(p.is_opened)),
                of(null).pipe(
                  switchMap(() => {
                    if (p.is_opened) {
                      return of(
                        setPopupInfo({
                          opened: true,
                          message:
                            'Il documento selezionato è correntemente aperto da un altro utente.',
                          type: 'BAD',
                          timer: 3000,
                        }),
                        resetCurrentCreateSessionAction()
                      );
                    }
                    return of();
                  })
                )
              )
            ),
            catchError((err) => {
              console.error(`[epic ${RETRIEVE_DOCUMENTO_OPENED}] error`, err);
              return EMPTY;
            })
          )
        ),
        of(setLoaders({ documentoLoader: false }))
      );
    })
  );

export default retrieveDocumentoOpenedEpic;
